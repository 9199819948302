import React, { useEffect, useState } from 'react';
import {
  Table, TableRow, TableCell, TableBody, IconButton,
  TablePagination,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Publish } from '@mui/icons-material';
import { useEffectAsync } from '../reactHelper';
import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import CollectionFab from '../settings/components/CollectionFab';
import CollectionActions from '../settings/components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import { useAdministrator, useSuperManager } from '../common/util/permissions';
import SearchHeader, { filterByKeyword } from '../settings/components/SearchHeader';
import { voltToPercentage } from '../common/util/converter';
import { lockIconSelector } from '../common/util/deviceCategories';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import DevicesManagementMenu from './components/DevicesManagementMenu';
import StyledTableHead from '../common/components/StyledTableHead';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  offline: {
    color: theme.palette.colors.negative,
  },
  online: {
    color: theme.palette.colors.positive,
  },
}));

const DevicesPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const positions = useSelector((state) => state.session.positions);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const admin = useAdministrator();
  const superManager = useSuperManager();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [searchKeyword, setSearchKeyword] = useState('');
  const [items, setItems] = useState([]);
  const [devices, setDevices] = useState(null);
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(true);

  const handleShowMap = (itemId) => {
    navigate(`/${itemId}`);
  };

  const showMap = {
    title: t('mapTitle'),
    icon: (<MapIcon fontSize="small" />),
    handler: handleShowMap,
  };

  function addBatteryOnDevices() {
    if (devices) {
      devices.forEach((device) => {
        Object.entries(positions).forEach((position) => {
          if (parseInt(position[0], 10) === device.id) {
            if (position[1].attributes.batteryLevel > 100 || !position[1].attributes.batteryLevel) {
              device.batteryLevel = voltToPercentage(position[1].attributes.power, device.attributes.batteryVoltage);
              if (device.batteryLevel < 0) {
                device.batteryLevel = 0;
              }
            } else {
              device.batteryLevel = position[1].attributes.batteryLevel;
            }
            device.lastUpdate = position[1].serverTime;
            device.blocked = position[1].attributes.blocked;
          }
        });
      });
      setItems([...devices]);
    }
  }

  useEffect(() => {
    addBatteryOnDevices();
  }, [devices, positions]);

  useEffectAsync(async () => {
    if (init) {
      setLoading(true);
    } else {
      setInit(false);
    }
    try {
      const response = await fetch('/api/devices');
      if (response.ok) {
        const devices = await response.json();
        const noStationDevices = [];
        devices.forEach((device) => {
          if (device.category !== 'bicycleParking') {
            noStationDevices.push(device);
          }
        });
        setDevices(noStationDevices);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  return (
    <PageLayout menu={<DevicesManagementMenu />} breadcrumbs={['devices']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <Table stickyHeader>
        <StyledTableHead>
          <TableRow>
            {superManager && <TableCell className={classes.columnAction} />}
            <TableCell className={classes.columnAction} />
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('deviceStatus')}</TableCell>
            <TableCell>{t('positionBattery')}</TableCell>
            <TableCell>{t('deviceCategory')}</TableCell>
            <TableCell>{t('positionBlocked')}</TableCell>
            <TableCell>{t('deviceLastUpdate')}</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
            <TableRow key={item.id}>
              {superManager &&
                (
                  <TableCell className={classes.columnAction} padding="none">
                    <IconButton size="small" onClick={() => navigate(`/devicesManagement/command-send/${item.id}`)}>
                      <Publish />
                    </IconButton>
                  </TableCell>
                )}
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions
                  itemId={item.id}
                  editPath={superManager ? '/devicesManagement/device' : null}
                  endpoint="devices"
                  setTimestamp={setTimestamp}
                  customAction={showMap}
                  noDelete={!admin}
                />
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell className={item.status !== 'online' ? classes.offline : classes.online}>{item.status}</TableCell>
              <TableCell className={(item.batteryLevel < 40 || !item.batteryLevel) ? classes.offline : classes.online}>
                {`${item.batteryLevel ? item.batteryLevel : 0} %`}
              </TableCell>
              <TableCell><img src={mapIcons[mapIconKey(item.category)]} alt="" /></TableCell>
              <TableCell>{lockIconSelector(item.blocked)}</TableCell>
              <TableCell>{formatTime(item.lastUpdate, 'DD/MM/YYYY - hh:mm:ss')}</TableCell>
            </TableRow>
          )) : (<TableShimmer columns={6} endAction />)}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ overflow: 'visible', paddingRight: 60 }}
      />
      {admin && (<CollectionFab editPath="/devicesManagement/device" />)}
    </PageLayout>
  );
};

export default DevicesPage;
