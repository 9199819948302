import React, { useState } from 'react';
import { getMRT_RowSelectionHandler, MaterialReactTable } from 'material-react-table';
import { Send } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffectAsync } from '../reactHelper';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import { calcAge } from '../common/util/converter';
import CustomFab from '../common/components/CustomFab';
import { useAttributePreference } from '../common/util/preferences';

const CustomNotificationSendPage = () => {
  const [items, setItems] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const notifications = useAttributePreference('notifications', 0);

  useEffectAsync(async () => {
    try {
      const response = await fetch('/api/users/rent');
      if (response.ok) {
        const res = await response.json();
        const resSupport = [];
        res.forEach((user) => {
          if (user.deviceLimit === 0 && user.userLimit === 0) {
            const userSupport = { ...user };
            userSupport.age = calcAge(user.attributes.dateBirth);
            userSupport.country = user.attributes.country;
            userSupport.province = user.attributes.province;
            userSupport.city = user.attributes.city;
            userSupport.address = user.attributes.address;
            resSupport.push(userSupport);
          }
        });
        setItems(resSupport);
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
  }, []);

  const columns = [
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'age',
      header: 'Age',
      filterVariant: 'range',
      filterFn: 'between',
    },
    {
      accessorKey: 'country',
      header: 'Country',
    },
    {
      accessorKey: 'province',
      header: 'Province',
    },
    {
      accessorKey: 'city',
      header: 'City',
    },
    {
      accessorKey: 'address',
      header: 'Address',
    },
  ];

  const sendNotifications = async (table) => {
    if (notifications !== -1 && table.getSelectedRowModel().rows.length > notifications) {
      alert(`Hai superato il numero di notifiche che hai a disposizione (${notifications}), riduci il numero di selezione o acquista nuove notifiche per continuare`);
      return;
    }
    let bodyContent = `notificationId=${id}`;
    table.getSelectedRowModel().rows.forEach((row) => {
      bodyContent += `&userIds=${items[row.index].id}`;
    });
    const res = await fetch('/api/customNotifications/send', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: bodyContent,
    });

    if (res.ok) {
      navigate(-1);
    } else {
      throw Error(await res.text());
    }
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsUsers']}>
      <MaterialReactTable
        columns={columns}
        data={items}
        enableRowNumbers
        enableRowSelection
        enablePagination={false}
        layoutMode="grid-no-grow"
        muiTableContainerProps={{
          sx: { maxHeight: '800px' },
        }}
        muiTableProps={{
          sx: {
            backgroundColor: 'white',
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            backgroundColor: 'white',
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: 'white',
          },
        }}
        muiTableBodyRowProps={({ row, staticRowIndex, table }) => ({
          onClick: (event) => getMRT_RowSelectionHandler({
            row,
            staticRowIndex,
            table,
          })(event),
          style: {
            cursor: 'pointer',
            userSelect: 'none',
          },
          sx: {
            backgroundColor: 'white',
          },
        })}
        muiTableHeadRowProps={{
          sx: {
            backgroundColor: 'white',
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            backgroundColor: 'white',
          },
        }}
        muiTopToolbarProps={{
          sx: {
            backgroundColor: 'white',
          },
        }}
        renderBottomToolbarCustomActions={({ table }) => (
          <CustomFab
            onPress={() => sendNotifications(table)}
            disabled={table.getSelectedRowModel().rows.length === 0}
          >
            <Send />
          </CustomFab>
        )}
      />
    </PageLayout>
  );
};

export default CustomNotificationSendPage;
