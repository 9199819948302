import React from 'react';
import { Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      bottom: `calc(${theme.dimensions.bottomBarHeight}px + ${theme.spacing(2)})`,
    },
    margin: 20,
  },
}));

const CustomFab = ({ children, onPress, disabled }) => {
  const classes = useStyles();

  if (!disabled) {
    return (
      <Fab size="medium" color="primary" className={classes.fab} onClick={onPress}>
        {children}
      </Fab>
    );
  }
  return '';
};

export default CustomFab;
