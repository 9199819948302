import React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import SensorsIcon from '@mui/icons-material/Sensors';
import PublishIcon from '@mui/icons-material/Publish';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  BorderStyle, Category,
  LocalParking,
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import palette from '../../common/theme/palette';
import { useAdministrator, useSuperManager } from '../../common/util/permissions';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton
    key={link}
    component={Link}
    to={link}
    selected={selected}
    sx={{
      '&.Mui-selected': { backgroundColor: palette.primary.main, color: 'white' },
      '&.Mui-selected:hover': { backgroundColor: palette.primary.main, color: 'white' },
      borderRadius: 20,
      marginLeft: 1,
      marginRight: 1,
    }}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const DevicesManagementMenu = () => {
  const t = useTranslation();
  const location = useLocation();
  const admin = useAdministrator();
  const superManager = useSuperManager();

  return (
    <List>
      <MenuItem
        title={`${t('devices')}`}
        link="/devicesManagement/devices"
        icon={<SensorsIcon style={{ color: location.pathname.startsWith('/devicesManagement/device') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/devicesManagement/device')}
      />
      <MenuItem
        title={`${t('stations')}`}
        link="/devicesManagement/stations"
        icon={<LocalParking style={{ color: location.pathname.startsWith('/devicesManagement/station') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/devicesManagement/station')}
      />
      {superManager && (
        <MenuItem
          title={t('sharedGeofences')}
          link="/devicesManagement/geofences"
          icon={<BorderStyle style={{ color: location.pathname.startsWith('/devicesManagement/geofence') ? 'white' : 'gray' }} />}
          selected={location.pathname.startsWith('/devicesManagement/geofence')}
        />
      )}
      {superManager && (
        <MenuItem
          title={t('sharedSavedCommands')}
          link="/devicesManagement/commands"
          icon={<PublishIcon style={{ color: location.pathname.startsWith('/devicesManagement/command') ? 'white' : 'gray' }} />}
          selected={location.pathname.startsWith('/devicesManagement/command')}
        />
      )}
      {superManager && (
        <MenuItem
          title={t('categories')}
          link="/devicesManagement/categories"
          icon={<Category style={{ color: location.pathname.startsWith('/devicesManagement/categor') ? 'white' : 'gray' }} />}
          selected={location.pathname.startsWith('/devicesManagement/categor')}
        />
      )}
      {admin && (
        <MenuItem
          title={t('settingsGroups')}
          link="/devicesManagement/groups"
          icon={<FolderIcon style={{ color: location.pathname.startsWith('/devicesManagement/group') ? 'white' : 'gray' }} />}
          selected={location.pathname.startsWith('/devicesManagement/group')}
        />
      )}
      {admin && (
        <MenuItem
          title={`${t('sharedNotifications')} Device`}
          link="/devicesManagement/notifications"
          icon={<NotificationsIcon style={{ color: location.pathname.startsWith('/devicesManagement/notification') ? 'white' : 'gray' }} />}
          selected={location.pathname.startsWith('/devicesManagement/notification')}
        />
      )}
    </List>
  );
};

export default DevicesManagementMenu;
